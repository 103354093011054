import React from 'react';
import TypingEffect from '../components/TypingEffect';
import Footer from '../components/Footer';
import Contact from '../components/Contact';
import screen from '../static/zenith-screen.png';

function Home() {
  return (
    <div className='flex flex-col justify-between min-h-screen'>
      <div className='relative overflow-hidden select-none'>
        <div className='bg-white pt-10 pb-14 sm:pt-16 lg:overflow-hidden lg:pt-24 lg:pb-24'>
          <div className='mx-auto max-w-5xl lg:px-8'>
            <div className='lg:grid lg:grid-cols-2 lg:gap-8'>
              <div className='mx-auto max-w-md px-6 sm:max-w-2xl sm:px-6 lg:flex lg:items-center lg:px-0 text-left'>
                <div className='lg:py-24'>
                  <h1 className='mt-4 text-4xl font-bold tracking-tight text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl'>
                    <span className='block text-cursive text-4xl whitespace-nowrap h-auto mb-5 mt-auto'>
                      Feeling stressed?
                    </span>
                    <span className='text-4xl text-violet-500 mb-5 inline-block'>
                      Find
                      <TypingEffect
                        texts={[
                          'peace',
                          'presence',
                          'clarity',
                          'purpose',
                          'confidence',
                          'hope'
                        ]}
                      />
                      with
                    </span>
                    <span className='text-black text-cursive mt-3 text-7xl flex flex-row flex-wrap align-bottom'>
                      <span className='mr-2'>Zenith</span>
                      <span className='text-title text-xl whitespace-nowrap h-auto mb-3 mt-auto'>
                        Life Coach
                      </span>
                    </span>
                    <span className='block text-cursive text-2xl h-auto mt-5'>
                      Peace of mind in your pocket.
                    </span>
                  </h1>
                  <div className='mt-10 sm:mt-12'>
                    <Contact />
                  </div>
                  <p className='mt-10 sm:mt-12 text-base text-gray-600 sm:text-xl lg:text-lg xl:text-xl'>
                    <span className='text-violet-800'>
                      How incredible would it feel
                    </span>{' '}
                    to have a world-class therapist and life coach who made you
                    their personal project, who was always just a text away,
                    around the clock?
                    <br />
                    <br />
                    Zenith is a digital coach which has been{' '}
                    <span className='text-violet-800'>expert trained</span> to
                    serve as your personal life coach, handling any form of
                    stress, anxiety or trauma, guiding you to inner peace and
                    presence, holding your hand every step of the way.
                    <br />
                    <br />
                    Therapy can be expensive, but Zenith was built to help as
                    many as possible. So, we make it as inexpensive as we can.
                    You don't have to break the bank for mental health.
                    <br />
                    <br />
                    Zenith uses LLM technology and world-class therapy resources
                    to help you achieve lasting mental wellness. And, we utilize
                    best-in-class encryption protocols to make sure your data is
                    always kept private and secure.
                    <br />
                    <br />
                    Now accepting early-access members.
                    <br />
                    <br />
                    P.S. - It's never too late to start :)
                  </p>
                </div>
              </div>
              <div className='mt-12 hidden lg:block'>
                <img className='' src={screen} alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
