import React from 'react';

function Footer() {
  return (
    <footer className='bg-white'>
      <div className='mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8'>
        <p className='mt-8 text-center text-base text-gray-400'>
          © {new Date().getFullYear()} Zenith Health LLC. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
