import React from 'react';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';

function Success() {
  let navigate = useNavigate();

  return (
    <div className='flex flex-col justify-between min-h-screen'>
      <div className='relative overflow-hidden select-none'>
        <div className='bg-white pt-10 pb-14 sm:pt-16 lg:overflow-hidden lg:pt-24 lg:pb-24'>
          <div className='mx-auto max-w-5xl lg:px-8'>
            <div className='mx-auto max-w-md px-6 sm:max-w-2xl sm:px-6'>
              <div className='lg:py-24'>
                <div>
                  <h3 className='text-violet-500 mb-5 text-5xl text-title'>
                    Got it ✍️
                  </h3>
                  <p className='text-xl mb-5'>
                    We'll be in touch shortly with your early-access pass.
                  </p>
                  <p className='text-xl mb-5'>
                    Thank you for trusting Zenith as your personal coach. We're
                    constantly working to improve your experience.
                  </p>
                  <button
                    className='block rounded-md bg-gray-800 py-3 px-4 font-medium text-white shadow hover:bg-gray-700'
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Success;
