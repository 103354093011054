import React, { useState, useEffect } from 'react';

const TypingEffect = ({ texts }) => {
  const [index, setIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const typingSpeed = 150; // delay between typing
    const deletingSpeed = 80; // delay between deleting
    let timer;

    if (isDeleting) {
      setIsTyping(false);
      timer = setTimeout(() => {
        setCurrentText(prev => prev.substring(0, prev.length - 1));
        if (currentText.length === 0) {
          setIsDeleting(false);
          setIndex(prev => (prev + 1) % texts.length);
        }
      }, deletingSpeed);
    } else {
      setIsTyping(true);
      timer = setTimeout(() => {
        setCurrentText(texts[index].substring(0, currentText.length + 1));
        if (currentText.length === texts[index].length) {
          setIsTyping(false);
          setTimeout(() => setIsDeleting(true), 2400);
        }
      }, typingSpeed);
    }

    return () => clearTimeout(timer);
  }, [currentText, isDeleting, index, texts]);

  const cursorStyle = {
    animation: isTyping
      ? 'none'
      : isDeleting
        ? 'none'
        : 'blinking 1.3s infinite'
  };

  return (
    <span
      id='hello'
      className='relative h-full inline-block leading-4 bg-violet-200 mr-1 pr-3 py-5 mx-2 rounded-b-xl rounded-tl-xl'
    >
      &nbsp;{currentText}
      <span
        className='absolute right-[7px] bg-violet-500 w-[3px] h-[74%] top-[13%]'
        style={cursorStyle}
      ></span>
    </span>
  );
};

export default TypingEffect;
