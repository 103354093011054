import React from 'react';
import { useNavigate } from 'react-router-dom';

function Contact() {
  const navigate = useNavigate();
  const url =
    'https://docs.google.com/forms/u/0/d/e/1FAIpQLSdhrFrpaUVZp2JmiZCIWVZXG2Ud_0dpSHWVNwIJGOoRQ2Y7Hw/formResponse';

  const handleSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    fetch(url, {
      method: 'POST',
      body: formData,
      mode: 'no-cors'
    }).then(function () {
      navigate('/success');
    });
    return false;
  };

  return (
    <form className='sm:mx-auto sm:max-w-xl lg:mx-0' onSubmit={handleSubmit}>
      <div className='min-w-0 flex-1'>
        <input
          id='name'
          name='entry.1316239870'
          type='text'
          placeholder='Name'
          className='block w-full rounded-md border-0 bg-gray-200 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400'
          autoComplete='off'
          required
        />
      </div>
      <div className='sm:flex mt-3'>
        <div className='min-w-0 flex-1'>
          <input
            id='phone'
            name='entry.1754427678'
            type='tel'
            placeholder='Phone'
            className='block w-full rounded-md border-0 bg-gray-200 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400'
            autoComplete='off'
            required
          />
        </div>
        <div className='mt-3 sm:mt-0 sm:ml-3'>
          <button
            type='submit'
            className='block w-full rounded-md bg-violet-500 py-3 px-4 font-medium text-white shadow hover:bg-violet-400'
          >
            Get Access
          </button>
        </div>
      </div>
      {/*<p className='text-gray-500 mt-2.5 text-center'>*/}
      {/*  We never sell your data, and we never send spam.*/}
      {/*</p>*/}
    </form>
  );
}

export default Contact;
